import React from "react";
import clsx from "clsx";

interface TextProps {
  className?: string;
  children: React.ReactNode;
  isMobile?: boolean;
}

const MainText = ({ className, children, isMobile }: TextProps) => {
  return (
    <p
      className={clsx(
        "px-2 text-text md:text-[26px] text-white",
        { "text-[4.8vw]": isMobile, "text-[26px]": !isMobile },
        className
      )}
    >
      {children}
    </p>
  );
};

export default MainText;
