import { AnimationScope, motion } from "framer-motion";
import { useVariants } from "../hooks/useVariant";

interface MouseCircleEventProps {
  isMobile: boolean;
  scope: AnimationScope<any>;
}

const MouseCircleEvent = ({ isMobile, scope }: MouseCircleEventProps) => {
  const { mouseXPosition, mouseYPosition, color } = useVariants(scope);

  const colorH = color % 360;
  return (
    <>
      {!isMobile && mouseXPosition > 0 && mouseYPosition > 0 && (
        <>
          <motion.div
            className="circle"
            animate={{
              opacity: 0.5,
              x: mouseXPosition - 20,
              y: mouseYPosition - 20,
              height: 40,
              width: 40,
              backgroundColor: `hsl(${colorH}, 100%, 50%)`,
              transition: {
                type: "spring",
                mass: 0.5,
              },
            }}
          ></motion.div>
          <motion.div
            className="circle"
            animate={{
              opacity: 0.4,
              x: mouseXPosition - 35,
              y: mouseYPosition - 35,
              height: 70,
              width: 70,
              backgroundColor: `hsl(${colorH}, 100%, 50%)`,
              transition: {
                type: "spring",
                mass: 0.5,
              },
            }}
          ></motion.div>
          <motion.div
            className="circle"
            animate={{
              opacity: 0.3,
              x: mouseXPosition - 50,
              y: mouseYPosition - 50,
              height: 100,
              width: 100,
              backgroundColor: `hsl(${colorH}, 100%, 50%)`,
              transition: {
                type: "spring",
                mass: 0.5,
              },
            }}
          ></motion.div>
        </>
      )}
    </>
  );
};

export default MouseCircleEvent;
