import React from "react";
import clsx from "clsx";

interface SolutionTextProps {
  className?: string;
  children: React.ReactNode;
  isMobile: boolean;
}

const SolutionText = ({ children, className, isMobile }: SolutionTextProps) => {
  return (
    <p
      className={clsx(
        "px-1  text-white",
        {
          "text-[4vw]": isMobile,
          "text-[3.4vw] md:text-[26px]": !isMobile,
        },
        className
      )}
    >
      {children}
    </p>
  );
};

export default SolutionText;
