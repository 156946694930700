import mainGif from "../assets2/mainGif.gif";
import circle from "../assets2/circle.png";
import star from "../assets2/star.png";

const MainGif = () => {
  return (
    <div className="relative max-w-[1000px] w-full mx-auto z-20">
      <img
        src={star}
        alt="별모양 장식"
        className="absolute w-[15vw] p:w-full max-w-[117px] top-[-50px] right-0"
      />
      <img
        className="absolute bottom-[-30px] left-0 w-[20vw] p:w-full max-w-[144px] p:left-8"
        src={circle}
        alt="원이 4개 모여있는 장식"
      />
      <img className="mx-auto" src={mainGif} alt="다양한 사람들 gif 이미지" />
    </div>
  );
};

export default MainGif;
