import clsx from "clsx";
import { useAnimate, stagger } from "framer-motion";

interface MenuButtonProps {
  href: string;
  children: string;
  className?: string;
  isMobile?: boolean;
  color: string;
}

const MenuButton = ({
  href,
  children,
  className,
  isMobile,
  color,
}: MenuButtonProps) => {
  const [scope, animate] = useAnimate();
  return (
    <a
      ref={scope}
      className={clsx(
        "px-[1vw] relative text-white letter-wrap font-semibold font-[Gothic A1] overflow-hidden flex",
        {
          "text-[3.5vw] md:text-[2.3vw] lg:text-[1.9vw] 2xl:text-[28px] h-[2.5vw]":
            isMobile,
          "text-[16px] lg:text-[20px] xl:text-[26px] h-[30px] xl:h-[40px]":
            !isMobile,
        },
        className
      )}
      onMouseEnter={() =>
        animate(".move-span", { y: "-100%" }, { delay: stagger(0.1) })
      }
      onMouseLeave={() =>
        animate(".move-span", { y: "0%" }, { delay: stagger(0.1) })
      }
      href={href}
    >
      {children.split("").map((child, i) => (
        <span
          className={clsx("move-span flex flex-col", {
            "justify-center items-center": isMobile,
          })}
          key={i}
        >
          <span>{child}</span>
          {!isMobile && <span className={color}>{child}</span>}
        </span>
      ))}
    </a>
  );
};

export default MenuButton;
