/* eslint-disable jsx-a11y/anchor-is-valid */
import emailJs from "@emailjs/browser";
import "react-toastify/dist/ReactToastify.css";

import MenuButton from "./components/MenuButton";
import Logo from "./assets2/grandly logo_white.png";
import bg from "./assets2/bg.jpg";
import bg1 from "./assets2/bg1.png";
import image2 from "./assets2/image2.png";
import image3 from "./assets2/image3.png";
import image4 from "./assets2/image4.png";
import image5 from "./assets2/image5.png";
import image6 from "./assets2/image6.png";
import image7 from "./assets2/image7.png";
import image8 from "./assets2/image8.png";
import image9 from "./assets2/image9.png";
import image10 from "./assets2/image10.png";
import image11 from "./assets2/image11.png";
import image12 from "./assets2/image12.png";
import image13 from "./assets2/image13.png";
import image14 from "./assets2/image14.png";
import image15 from "./assets2/image15.png";
import Postbox from "./assets2/Postbox.png";
import Grandly from "./assets2/Grandly®.png";

import dot from "./assets2/dot.png";
import downArrow from "./assets2/downArrow.png";
import conceptBadge from "./assets2/conceptBadge.png";
import orangeStar from "./assets2/orangeStar.png";

import SolutionText from "./components/SolutionText";
import BigText from "./components/BigText";
import ProductText from "./components/ProductText";
import useInput from "./hooks/useInput";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import clsx from "clsx";
import { useAnimate, motion } from "framer-motion";
import MouseCircleEvent from "./components/MouseCircleEvent";
import MainGif from "./components/MainGif";
import MainTitle from "./components/MainTitle";
import ConceptText from "./components/ConceptText";

const App = () => {
  const [name, onChangeName, setName] = useInput();
  const [email, onChangeEmail, setEmail] = useInput();
  const [phone, onChangePhone, setPhone] = useInput();
  const [title, onChangeTitle, setTitle] = useInput();
  const [message, onChangeMessage, setMessage] = useInput();
  const [isMobile, setIsMobile] = useState(true);
  const [isOpenMenu, setIsOpenMenu] = useState(true);
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [scope, animate] = useAnimate();
  const [menuBarX, setMenuBarX] = useState(0);

  useEffect(() => {
    if (window) {
      setIsMobile(/Mobi/i.test(window.navigator.userAgent));
    }
  }, []);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const toastId = toast("메시지를 발신하는 중입니다.", {
      position: "bottom-right",
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });
    try {
      await emailJs.send(
        process.env.REACT_APP_EMAIL_SERVICE_KEY as string,
        process.env.REACT_APP_EMAIL_TEMPLATE_KEY as string,
        {
          name,
          email,
          phone,
          title,
          message,
        }
      );

      setEmail("");
      setName("");
      setPhone("");
      setTitle("");
      setMessage("");

      toast.update(toastId, {
        render: "메시지가 성공적으로 발신되었습니다.",
        type: "success",
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      toast.update(toastId, {
        render: "알 수 없는 문제가 발생했습니다. 다시 시도해주세요",
        type: "error",
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  useEffect(() => {
    emailJs.init(process.env.REACT_APP_EMAIL_PUBLIC_KEY as string);
  }, []);

  const menuCloseAnimation = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const entry = entries[0];

      if (entry.isIntersecting && !isOpenMenu) {
        animate("header", { y: "0%" });
        setIsOpenMenu(true);
      }
    },
    [animate, isOpenMenu]
  );

  useEffect(() => {
    if (isOpenMenu || isOpenMobileMenu) {
      animate("#menu-button", { opacity: 0, display: "none" });
    } else {
      animate("#menu-button", { opacity: 1, display: "block" }, { delay: 0.5 });
    }
  }, [animate, isOpenMenu, isOpenMobileMenu]);

  useEffect(() => {
    if (window) {
      const homeDiv = document.querySelector("#home");

      const observer = new IntersectionObserver(menuCloseAnimation);

      observer.observe(homeDiv as Element);

      window.addEventListener("scroll", (e) => {
        if (window.scrollY > 0) {
          setIsOpenMenu(false);
          animate("header", { y: "-100%" });
        }

        if (window.scrollX > 0) {
          setMenuBarX(window.scrollX);
        }
      });
    }
  }, [animate, menuCloseAnimation]);

  return (
    <>
      {isMobile && isOpenMobileMenu && (
        <div
          id="backdrop"
          onClick={(e) => {
            setIsOpenMobileMenu((prev) => !prev);
          }}
          className="fixed w-screen h-full z-40 bg-[rgba(0,0,0,0.3)]"
        >
          <div className="mx-[12px] relative top-[12px] bg-primary_green rounded-[5px] p-[12px] z-50">
            <div className="flex justify-between items-center  mb-[50px]">
              <a href="#home">
                <img src={Logo} className="w-[70px]" alt="브랜들리 로고" />
              </a>
              <button>
                <svg
                  width="15"
                  height="3"
                  viewBox="0 0 15 3"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1.50033C0 0.901106 0.478795 0.416992 1.07143 0.416992H13.9286C14.5212 0.416992 15 0.901106 15 1.50033C15 2.09954 14.5212 2.58366 13.9286 2.58366H1.07143C0.478795 2.58366 0 2.09954 0 1.50033Z"
                    fill="white"
                  />
                </svg>
              </button>
            </div>
            <ul className="flex flex-col gap-[10px] text-[20px] text-white font-bold font-[Gothic A1]">
              <li>
                <a href="#home">HOME</a>
              </li>
              <li>
                <a href="#mission">MISSION</a>
              </li>
              <li>
                <a href="#solution">SOLUTION</a>
              </li>
              <li>
                <a href="#concept">CONCEPT</a>
              </li>
              <li>
                <a href="#contact">CONTACT US</a>
              </li>
              <li>
                <a href="#">ABOUT GRANDLY</a>
              </li>
            </ul>
          </div>
        </div>
      )}
      {isMobile ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            backgroundImage: `url(${bg1})`,
          }}
        ></div>
      ) : (
        <div
          style={{
            position: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            top: 0,
            left: 0,
            zIndex: 0,
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            backgroundImage: `url(${bg})`,
          }}
        ></div>
      )}

      <div
        ref={scope}
        style={
          isMobile
            ? {}
            : {
                backgroundImage: `url(${bg})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundAttachment: "fixed",
                backgroundRepeat: "no-repeat",
              }
        }
        className="bg-[rgba(255,255,255,0.5)] w-full z-10 "
      >
        <MouseCircleEvent isMobile={isMobile} scope={scope} />
        <motion.button
          id="menu-button"
          whileHover={{ scale: 1.1 }}
          onClick={() => {
            if (isMobile) {
              setIsOpenMobileMenu(!isOpenMobileMenu);
            } else {
              setIsOpenMenu(true);
            }
            animate("header", { y: "0%" }, { delay: 0.3 });
          }}
          className="fixed right-[20px] top-[20px] py-[8px] px-[12px] rounded-full z-50 bg-primary_green text-[16px] font-semibold text-white overflow-hidden"
        >
          <span>Menu</span>
        </motion.button>
        <div id="home"></div>
        {isMobile ? (
          <header className="mx-[12px] h-[40px] flex justify-between items-center rounded-[5px] relative top-[12px] px-[12px] bg-primary_green">
            <a href="#home" className="block">
              <img src={Logo} className="w-[64px]" alt="그랜들리 로고" />
            </a>
            <button
              className="p-[4px]"
              onClick={() => setIsOpenMobileMenu((prev) => !prev)}
            >
              <svg
                width="15"
                height="13"
                viewBox="0 0 15 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 1.08333C0 0.484115 0.478795 0 1.07143 0H13.9286C14.5212 0 15 0.484115 15 1.08333C15 1.68255 14.5212 2.16667 13.9286 2.16667H1.07143C0.478795 2.16667 0 1.68255 0 1.08333ZM0 6.5C0 5.90078 0.478795 5.41667 1.07143 5.41667H13.9286C14.5212 5.41667 15 5.90078 15 6.5C15 7.09922 14.5212 7.58333 13.9286 7.58333H1.07143C0.478795 7.58333 0 7.09922 0 6.5ZM15 11.9167C15 12.5159 14.5212 13 13.9286 13H1.07143C0.478795 13 0 12.5159 0 11.9167C0 11.3174 0.478795 10.8333 1.07143 10.8333H13.9286C14.5212 10.8333 15 11.3174 15 11.9167Z"
                  fill="white"
                />
              </svg>
            </button>
          </header>
        ) : (
          <header
            style={{
              transform: `translateX(-${menuBarX}px)`,
            }}
            className={clsx(
              "fixed py-[24px] w-[800px] minWidth:w-full z-50 bg-primary_green",
              {
                "lg:py-[1.5vw]": isMobile,
              }
            )}
          >
            <nav className="flex justify-between px-[16px] items-center">
              <ul className="flex justify-center">
                <li>
                  <MenuButton
                    color="text-primary_red"
                    isMobile={isMobile}
                    href="#mission"
                  >
                    MISSON
                  </MenuButton>
                </li>
                <li>
                  <MenuButton
                    color="text-primary_orange"
                    isMobile={isMobile}
                    href="#solution"
                  >
                    SOLUTION
                  </MenuButton>
                </li>
                <li>
                  <MenuButton
                    color="text-primary_yellow"
                    isMobile={isMobile}
                    href="#concept"
                  >
                    CONCEPT
                  </MenuButton>
                </li>
              </ul>
              {!isMobile && (
                <a href="#home">
                  <img src={Logo} alt="그랜들리 로고" className="w-[200px]" />
                </a>
              )}
              <ul className="flex justify-center items-center gap-[8px]">
                <li>
                  <MenuButton
                    color="text-primary_violet"
                    isMobile={isMobile}
                    href="#contact"
                  >
                    CONTACT US
                  </MenuButton>
                </li>
                <li className="flex items-center justify-center">
                  <motion.button
                    className={clsx(
                      "px-[12px] h-[30px] lg:h-[40px] xl:h-[50px] relative bg-primary_orange rounded-full text-white line-clamp-1 font-semibold font-[Gothic A1]",
                      {
                        "text-[3.5vw] md:text-[2.3vw] lg:text-[1.9vw] 2xl:text-[28px] px-[2vw] py-[1vw]":
                          isMobile,
                        "text-[16px] lg:text-[20px] xl:text-[26px]": !isMobile,
                      }
                    )}
                    whileHover={{ scale: 1.1 }}
                  >
                    <p className="mb-[3px]">
                      {isMobile ? "ABOUT" : "ABOUT GRANDLY"}
                    </p>
                  </motion.button>
                </li>
              </ul>
            </nav>
          </header>
        )}

        <section
          className={clsx("flex flex-col z-20", {
            " gap-[160px] pt-[360px]": isMobile,
            "gap-[150px] pt-[600px] min-w-[800px]": !isMobile,
          })}
        >
          {isMobile ? (
            <>
              <MainTitle isMobile={isMobile} />
              <MainGif />
            </>
          ) : (
            <>
              <MainGif />
              <MainTitle isMobile={isMobile} />
            </>
          )}
          <div id="mission"></div>

          <div className="flex">
            <div className="relative mx-auto max-w-[1000px] w-full  z-20">
              <img
                className="absolute top-[-10vw] p:top-[-100px] w-[20vw] p:w-full max-w-[200px]"
                src={dot}
                alt="dot 장식 이미지"
              />
              <img
                className="mx-auto"
                src={image2}
                alt="그랜들리 미션 이미지"
              />
              <p
                className={clsx("absolute  text-subText text-white", {
                  "text-[4.6vw] right-[6%]  top-[13%]": isMobile,
                  "text-[26px] leading-[44px]  right-[16%] lg:right-[18%]  top-[16%]":
                    !isMobile,
                })}
              >
                주식회사 그랜 들리는 연령 차별로 생기는 <br />
                사회적 태도와 편견을 없애기 위해 노력합니다. <br />
                나이, 외형 등 사회가 정한 기준을 벗어나 <br />
                그랜들리만의 방식으로 사회문제를 <br />한 단계씩 풀어 나아가려
                합니다.
              </p>
              <div id="solution"></div>
            </div>
          </div>
          <div className="relative  z-20">
            <img
              className="relative z-20 mx-auto"
              src={image3}
              alt="Before walking stick 아이콘"
            />

            <div className="relative mx-auto max-w-[1000px] w-full">
              <img
                src={downArrow}
                alt="화살표 장식"
                className=" absolute right-0 top-[-40vw] p3:top-[-400px] z-30 max-w-[100px] w-[12vw] p2:w-full"
              />
              <img
                className={clsx("relative full:top-[-260px] mx-auto z-10", {
                  "top-[-20vw] 2xl:top-[-15vw]": isMobile,
                  "top-[-200px]": !isMobile,
                })}
                src={image4}
                alt="지팡이 구조 이미지"
              />
            </div>
            <div className="relative">
              <div
                className={clsx(
                  "relative max-w-[800px] flex justify-end mx-auto",
                  {
                    "top-[-8vw]": isMobile,
                    "top-[-130px]": !isMobile,
                  }
                )}
              >
                <img
                  src={downArrow}
                  alt="화살표 장식"
                  className="absolute z-30 left-0 top-[-100px]  hidden md:block"
                />
                <div className="relative">
                  <img src={image5} alt="솔루션 배경 이미지" />
                  <h2
                    className={clsx(
                      "absolute left-[12%] top-[10%] text-[5vw] p3:text-[40px] font-semibold text-primary_violet z-20"
                    )}
                  >
                    Solution
                  </h2>
                  <p
                    className={clsx(
                      "absolute left-[5%] top-[40%] font-semibold text-white",
                      {
                        "text-[4vw]": isMobile,
                        "text-[3.2vw] md:text-[23px]": !isMobile,
                      }
                    )}
                  >
                    노인과 장애인들의 자립을 방해하는 가장 큰 과제 중 <br />
                    하나인 이동성을 해결할 수 있는 데일리 케어 지팡이 입니다.
                  </p>
                </div>
              </div>
            </div>
            <div className="relative ">
              <div
                className={clsx("relative ", {
                  "top-[-6vw]": isMobile,
                  "top-[-100px]": !isMobile,
                })}
              >
                <img className="mx-auto" src={image6} alt="지팡이 이미지" />
              </div>
              <div id="concept"></div>
            </div>
          </div>
          <div className="relative mx-auto max-w-[900px] w-full  z-20">
            <img
              src={conceptBadge}
              alt="뱃지"
              className=" absolute z-10 max-w-[200px] bottom-0 w-[25vw] p3:w-full"
            />
            <div className="max-w-[800px] mx-auto relative">
              <img className="mx-auto" src={image7} alt="grandly 솔루션" />
              <div className="absolute top-[4%] left-[5%] flex flex-col justify-center items-start mx-auto">
                <SolutionText
                  isMobile={isMobile}
                  className="bg-primary_violet mb-[16px]"
                >
                  나이는 숫자에 불과하고, 경험은 노련함을 이야기합니다.
                </SolutionText>
                <SolutionText
                  isMobile={isMobile}
                  className="bg-primary_violet mb-[16px]"
                >
                  ‘늙었다’는 말로 기회를 잃은 적 있나요?
                </SolutionText>
                <SolutionText
                  isMobile={isMobile}
                  className="bg-primary_violet mb-[16px]"
                >
                  ‘젊은이들의 일’ 이라고 해서 꿈을 포기한 적 있나요?
                </SolutionText>
                <SolutionText
                  isMobile={isMobile}
                  className="bg-primary_violet mb-[16px]"
                >
                  당신의 삶에 깊이를 더하고,
                </SolutionText>
                <SolutionText
                  isMobile={isMobile}
                  className="bg-primary_violet mb-[16px]"
                >
                  모든 순간이 당신의 아름다움을 반영하게 합니다.
                </SolutionText>
              </div>
            </div>
          </div>
          <div className="relative mx-auto  z-20">
            <div className=" absolute top-0 flex flex-col justify-center items-start gap-3">
              <BigText isMobile={isMobile} className="bg-primary_orange">
                그랜들리는
              </BigText>
              <BigText isMobile={isMobile} className="bg-primary_orange">
                당신의 삶에 깊이를 위해
              </BigText>
              <BigText isMobile={isMobile} className="bg-primary_orange">
                노력합니다.
              </BigText>
            </div>
            <img src={image8} alt="특허목록 이미지" />
          </div>
          <div className="relative  z-20">
            <div className="relative flex justify-start max-w-[900px] w-full mx-auto mb-[60px]">
              <img
                src={orangeStar}
                alt="별 장식"
                className="absolute z-20 bottom-0 w-[12vw] p2:w-full max-w-[120px]"
              />
              <div className="mx-auto max-w-[800px] w-full">
                <img
                  className="relative left-0 mb-[1vw] max-w-[700px] w-full  "
                  src={image9}
                  alt="DAILY CARE NEW WALKING STICK"
                />
              </div>
            </div>
            <div className="mb-[60px]">
              <img
                className="mx-auto"
                src={image10}
                alt="지팡이를 잡고 걷는 노신사"
              />
            </div>
            <img
              className="mx-auto mb-[60px]"
              src={image11}
              alt="지팡이를 잡고 잔디밭을 걷는 부부"
            />
            <div className="flex flex-col justify-center items-start mb-[60px]">
              <ConceptText
                isMobile={isMobile}
                className="bg-primary_violet mb-[16px] mx-auto"
              >
                불편함이 아닙니다.
              </ConceptText>
              <ConceptText
                isMobile={isMobile}
                className="bg-primary_violet mb-[16px] mx-auto"
              >
                당신은 멋스러운 아이템을
              </ConceptText>
              <ConceptText
                isMobile={isMobile}
                className="bg-primary_violet mb-[16px] mx-auto"
              >
                하나 사용하게 되었을 뿐입니다.
              </ConceptText>
            </div>
            <img
              className="mx-auto mb-[60px]"
              src={image12}
              alt="지팡이 약 보관 기능, 심박 측정 기능, 수납 기능 이미지"
            />
            <img
              className="mx-auto mb-[60px]"
              src={image13}
              alt="4가지 색상의 지팡이 이미지"
            />
            <img
              className="mx-auto mb-[60px]"
              src={image14}
              alt="어플로 심박을 체크하는 이미지"
            />
            <img
              className="mx-auto mb-[60px]"
              src={image15}
              alt="지팡이 하단 탭 기능"
            />
            <div className="mb-[100px] flex justify-center">
              <video
                poster="/images/videoPoster.jpg"
                className="max-w-[800px] w-full"
                controls
                preload="none"
              >
                <source src="/videos/video.mp4" type="video/mp4" />
              </video>
            </div>
            <div className="mt-[5vw] max-w-[800px] w-full mx-auto bg-[rgba(255,255,255,0.6)]">
              <div className="border-b-[4px] border-b-black flex justify-between">
                <ProductText>길이</ProductText>
                <ProductText>770~920mm</ProductText>
              </div>
              <div className="border-b-[4px] border-b-black flex justify-between">
                <ProductText>보관사이즈</ProductText>
                <ProductText>약300mm</ProductText>
              </div>
              <div className="border-b-[4px] border-b-black flex justify-between">
                <ProductText>길이조절</ProductText>
                <ProductText>{`150mm(미세조절: 3mm)`}</ProductText>
              </div>
              <div className="border-b-[4px] border-b-black flex justify-between">
                <ProductText>무게</ProductText>
                <ProductText>230g</ProductText>
              </div>
              <div className="border-b-[4px] border-b-black flex justify-between">
                <ProductText>몸체 재질</ProductText>
                <ProductText>알루미늄7075</ProductText>
              </div>
              <div className="border-b-[4px] border-b-black flex justify-between">
                <ProductText>손잡이 재질</ProductText>
                <ProductText>PP</ProductText>
              </div>
              <div className="border-b-[4px] border-b-black flex justify-between">
                <ProductText>고무팁 재질</ProductText>
                <ProductText>합성고무 및 알루미늄</ProductText>
              </div>
              <div className="flex justify-between">
                <ProductText>권장신장</ProductText>
                <ProductText>150~180cm</ProductText>
              </div>
            </div>
            <div className="flex justify-center mt-[8vw]">
              <button className="bg-primary_green text-white text-[8vw] md:text-[80px] px-[100px] font-bold">
                BUY NOW
              </button>
            </div>
            <div id="contact"></div>
          </div>
          <div className="z-20">
            <img
              className="mx-auto mb-[1vw]"
              src={Postbox}
              alt="우체통 이미지"
            />
            <div className="flex flex-col justify-center items-center gap-[1vw] mb-[6vw]">
              <p className="bg-primary_yellow text-black text-[5vw] md:text-[32px] xl:text-[38px] font-medium">
                도움이 필요하신가요?
              </p>
              <p className="bg-primary_yellow text-black text-[5vw] md:text-[32px] xl:text-[38px] font-medium">
                {`이메일을 보내주시면 빠르게 답변드릴게요 : - )`}
              </p>
            </div>
            <form
              onSubmit={onSubmit}
              className="flex flex-col gap-[1vw] w-full max-w-[800px] mx-auto"
            >
              <input
                className="bg-[rgba(255,255,255,0.6)] border-[1px] border-black w-full text-[3vw] md:text-[22px] xl:text-[26px] p-3 placeholder:text-black"
                placeholder="성함 (NAME)"
                id="name"
                name="name"
                required
                value={name}
                onChange={onChangeName}
              />
              <input
                className="bg-[rgba(255,255,255,0.6)] border-[1px] border-black w-full text-[3vw] md:text-[22px] xl:text-[26px] p-3 placeholder:text-black"
                placeholder="이메일 (E-MAIL)"
                id="email"
                name="email"
                type="email"
                required
                value={email}
                onChange={onChangeEmail}
              />
              <input
                className="bg-[rgba(255,255,255,0.6)] border-[1px] border-black w-full text-[3vw] md:text-[22px] xl:text-[26px] p-3 placeholder:text-black"
                placeholder="연락처 (PHONE)"
                id="phone"
                name="phone"
                required
                type="tel"
                value={phone}
                onChange={onChangePhone}
              />
              <input
                className="bg-[rgba(255,255,255,0.6)] border-[1px] border-black w-full text-[3vw] md:text-[22px] xl:text-[26px] p-3 placeholder:text-black"
                placeholder="제목 (TITLE)"
                id="title"
                name="title"
                required
                type="text"
                value={title}
                onChange={onChangeTitle}
              />
              <textarea
                placeholder="내용 (MESSAGE)"
                className="h-[350px] bg-[rgba(255,255,255,0.6)] border-[1px] border-black w-full text-[3vw] md:text-[22px] xl:text-[26px] p-3 placeholder:text-black"
                id="message"
                name="message"
                required
                value={message}
                onChange={onChangeMessage}
              ></textarea>
              <p className="bg-[rgba(255,255,255,0.6)] text-[3.6vw] md:text-[22px] xl:text-[26px] p-3 font-semibold">
                귀사의 검열정책에 따라 메일이 스팸처리 될 수 있습니다.
              </p>
              <p className="bg-[rgba(255,255,255,0.6)] text-[3.6vw] md:text-[22px] xl:text-[26px] p-3 font-semibold">
                스팸메일 확인 부탁드립니다.
              </p>
              <div className="flex justify-end mt-[5.7vw]">
                <button
                  type="submit"
                  className="bg-primary_green text-[5vw] md:text-[38px] font-bold text-white px-[3vw] 2xl:px-[60px]"
                >
                  보내기
                </button>
              </div>
            </form>
            <img
              src={Grandly}
              className="mt-[7.3vw] mx-auto"
              alt="그랜들리 로고"
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default App;
