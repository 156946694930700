import MainText from "./MainText";
import clsx from "clsx";

interface MainTitleProps {
  isMobile: boolean;
}

const MainTitle = ({ isMobile }: MainTitleProps) => {
  return (
    <div className="flex flex-col justify-center items-start mx-auto  z-20">
      <MainText
        isMobile={isMobile}
        className={clsx("bg-primary_red", {
          "mb-[3vw]": isMobile,
          "mb-[40px]": !isMobile,
        })}
      >
        나이는 숫자에 불과하고,
      </MainText>
      <MainText
        isMobile={isMobile}
        className={clsx("bg-primary_red", {
          "mb-[3vw]": isMobile,
          "mb-[40px]": !isMobile,
        })}
      >
        경험은 노련함을 이야기 합니다.
      </MainText>
      <MainText
        isMobile={isMobile}
        className={clsx("bg-primary_orange", {
          "mb-[2vw]": isMobile,
          "mb-[20px]": !isMobile,
        })}
      >
        ‘늙었다’는 말로 기회를 잃은적 있나요?
      </MainText>
      <MainText
        isMobile={isMobile}
        className={clsx("bg-primary_orange", {
          "mb-[3vw]": isMobile,
          "mb-[40px]": !isMobile,
        })}
      >
        ‘젊은이들의 일’ 이라고 해서 꿈을 포기한 적 있나요?
      </MainText>

      <MainText
        isMobile={isMobile}
        className={clsx("bg-primary_green", {
          "mb-[2vw]": isMobile,
          "mb-[20px]": !isMobile,
        })}
      >
        당신의 삶에 깊이를 더하고,
      </MainText>
      <MainText
        isMobile={isMobile}
        className={clsx("bg-primary_green", {
          "mb-[3vw]": isMobile,
          "mb-[40px]": !isMobile,
        })}
      >
        모든 순간이 당신의 아름다움을 반영하게 합니다.
      </MainText>
    </div>
  );
};

export default MainTitle;
