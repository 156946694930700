import clsx from "clsx";
import React from "react";

interface ProductTextProps {
  className?: string;
  children: React.ReactNode;
}

const ProductText = ({ children, className }: ProductTextProps) => {
  return (
    <p
      className={clsx(
        "text-[3vw] px-2 md:text-[32px] xl:text-[40px] text-black font-medium py-[1.2vw]",
        className
      )}
    >
      {children}
    </p>
  );
};

export default ProductText;
