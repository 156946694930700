import useMouse from "@react-hook/mouse-position";

let color = 0;

let mouseXPosition = 0;
let mouseYPosition = 0;

export const useVariants = (ref: React.MutableRefObject<null>) => {
  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  });

  color = color + 5;

  if (mouse.clientX !== null) {
    mouseXPosition = mouse.clientX;
  } else {
    return { mouseXPosition, mouseYPosition, color };
  }

  if (mouse.clientY !== null) {
    mouseYPosition = mouse.clientY;
  } else {
    return { mouseXPosition, mouseYPosition, color };
  }

  return { mouseXPosition, mouseYPosition, color };
};
