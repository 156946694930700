import clsx from "clsx";
import React from "react";

interface BigTextProps {
  className?: string;
  children: React.ReactNode;
  isMobile: boolean;
}

const BigText = ({ className, children, isMobile }: BigTextProps) => {
  return (
    <p
      className={clsx(
        "px-1 text-black font-medium",
        {
          "text-[5vw]": isMobile,
          " text-[4.3vw] md:text-[40px] 2xl:text-[40px]": !isMobile,
        },
        className
      )}
    >
      {children}
    </p>
  );
};

export default BigText;
